<template>
  <div>
    <div class="maintitle">订单详情</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">订单号</el-col>
      <el-col :span="21" class="text-left">{{info.ordersn}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">联系人</el-col>
      <el-col :span="21" class="text-left">{{info.contact}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">联系电话</el-col>
      <el-col :span="21" class="text-left">{{info.tel}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">地址</el-col>
      <el-col :span="21" class="text-left">{{info.address}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">下单时间</el-col>
      <el-col :span="21" class="text-left">{{info.ordertime}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">回收人员</el-col>
      <el-col :span="21" class="text-left">{{info.hsginfo}}</el-col>
    </el-row>    
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">收购详情</el-col>
      <el-col :span="21" class="text-left" v-html='info.orderdetail'></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">收购总价</el-col>
      <el-col :span="21" class="text-left">{{info.total}}元</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">收购时间</el-col>
      <el-col :span="21" class="text-left">{{info.sgtime}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">评价星级</el-col>
      <el-col :span="21" class="text-left">{{info.pingjia_text}}</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3" class="text-right">评价详情</el-col>
      <el-col :span="21" class="text-left">{{info.pjdetail}}</el-col>
    </el-row>
    <el-row :gutter="20" v-if="info.status == 1">
      <el-select v-model="slcthsg" placeholder="请选择回收哥" style="width:200px;">
        <el-option
          v-for="(item,index) in hsglist"
          :key="index"
          :label="item.name+'('+item.tel+')'"
          :value="item.uuid"
        ></el-option>
      </el-select>
      <el-button class="margin-left" @click="paidan">派单</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: [],
      hsglist: [],
      slcthsg: ""
    };
  },
  mounted() {},
  methods: {
    getinfo() {
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/order/getinfo",
        data: { ordersn: this.$route.params.id }
      }).then(res => {
        this.info = res.data.info;
        this.hsglist = res.data.list;
      });
    },
    paidan() {
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/order/pandan",
        data: { ordersn: this.info.ordersn, uuid: this.slcthsg }
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
            type: "info",
            message: "派单成功"
          });
          this.$router.go(-1);
        }
      });
    }
  },
  created() {
    this.getinfo();
  }
};
</script>

<style>
</style>
